
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import ApiService from "@/core/services/ApiService";
import EquipmentsForm from "@/views/catalog/equipment/EquipmentsForm.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "Equipments",
  components: {
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("isize"), key: "size", size: "extra_small" },
      { column: t("equipments_subtype"), key: "equipments_subtype" },
      { column: t("equipments_group"), key: "equipment_group" },
      { column: t("inote"), key: "note", size: "extra_large" },
    ]);
    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const isLoading = ref(true);
    const search = ref("");
    const tableButtons = ref([]);
    const dataList = ref([] as any);
    const dataObject = ref([] as any);
    const dialogVisible = ref(false);
    const total = ref(0);
    const itemID = ref("");
    const modalMode = ref("add");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      last_page: 1,
      total: 1,
    });

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getElements();
    };
    const currentPageChange = (val) => {
      pagination.value.page = val;
      getElements();
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: create")
            .length > 0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: update")
            .length > 0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: show")
            .length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: delete")
            .length > 0
        );
      }
      return false;
    });

    const forceDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: force_delete")
            .length > 0
        );
      }
      return false;
    });

    const createElement = () => {
      router.push({ name: "equipmentsCatalogsCreate" });
    };

    const getElements = () => {
      store.commit("setLoadingStatus", true);
      store.commit("setLoading", false);
      ApiService.query(`/api/equipments/lists`, {
        params: {
          name: search.value,
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
        },
      }).then(({ data }) => {
        isLoading.value = false;
        const items: Record<string, any> = [];
        dataList.value = data.equipments;
        pagination.value.total = data.pagination.total;
        pagination.value.page = data.pagination.current_page;
        pagination.value.last_page = data.pagination.last_page;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        data.equipments.forEach((element) => {
          const td = {
            id: element.id,
            equipment_subtype_id: element.equipment_subtype_id,
            equipment_group_id: element.equipment_group_id,
            name: element.name,
            equipments_subtype: element.equipment_subtype.name,
            equipment_group: element.equipment_group
              ? element.equipment_group.code
              : "-",
            size: element.size,
            manufacturing_materials: element.manufacturing_materials,
            temperature: element.temperature,
            note: element.note,
            buttons: [{ type: "EDIT", id: element.id }],
          };
          items.push(td);
        });
        dataObject.value = items;
        dataList.value = items;
        store.commit("setLoadingStatus", false);
      });
    };

    const resetModal = () => {
      dialogVisible.value = false;
      getElements();
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("equipments", [
        { route: "/equipments-catalogs", label: "equipments" },
      ]);
      getElements();
    });

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      store.commit("setLoading", false);
      ApiService.delete(
        `/api/equipments/${deleteId.value}?force_delete=true`
      ).then(({ data }) => {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getElements();
      });
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        router.push({ name: "equipmentsCatalogsEdit", params: { id: param1 } });
      }
      if (param2 == "REMOVE") {
        deleteDialog(param1);
      }
    };

    const onSearch = () => {
      dataObject.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      /*dataObject.value.forEach((element) => {
        element.equipment_group = element.equipment_group
          ? element.equipment_group.code
          : "-";
      });*/
      if (!dataObject.value.length) {
        getElements();
      }
    };

    return {
      forceDelete,
      user,
      canCreate,
      canUpdate,
      canShow,
      canDelete,
      dataList,
      dataObject,
      dialogVisible,
      itemID,
      modalMode,
      header,
      tableButtons,
      isLoading,
      search,
      pagination,
      showDelete,
      deleteDialog,
      deleteElement,
      currentPageChange,
      setItemsPerPage,
      onSearch,
      actionsButton,
      createElement,
      resetModal,
      getElements,
    };
  },
});
